import { fakeAxios as axios } from '../../utils/fakeAxios';
// import { baseAxios as axios } from '../axios/axios';
import { IProfile } from './profile.contracts';
import config from '../../config/config';

const baseUrl = `${config.profileBaseUrl}?GET=json-data&`;

export type ProfileRequestParams = { k: string; s: string; p: string; u: string };

const getProfile = async ({ k, s, p, u }: ProfileRequestParams) => {
  const endpoint = `${baseUrl}k=${k}&s=${s}&p=${p}&u=${u}`;
  const profile = await axios.get<IProfile>(endpoint);
  return profile.data;
};

export { getProfile };
