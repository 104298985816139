import { Route, Routes } from 'react-router-dom';

import {
  MainPage,
  OrderFormCreate,
  OrderFormUpdate,
  AllTariffs,
  AdminkaLayout,
  MainLayout,
  NewsPage,
  SignIn,
  AdminkaNews,
  NewsFormCreate,
  NewsFormUpdate,
  Order,
  AdminkaOrdersActive,
  AdminkaOrdersArchived,
  AdminkaOrdersCanceled,
  AdminkaDistricts,
  TariffFormCreate,
  TariffFormEdit,
  AdminkaArchivedTariffs,
  AdminkaWireLessTariffs,
  AdminkaWiredTariffs,
  Forbidden,
  E404,
  DistrictFormCreate,
  DistrictFormEdit,
  Profile,
  ProfileLayout,
} from '../view';

import RequireAuth from '../components/requireAuth/RequireAuth';
import { Role } from '../storeSlices/authSLice/authStore.contracts';
import { ForUnloggedUsers } from '../components';
import config from '../config/config';
import { Box } from '@mui/material';

const RouterView = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<MainPage />} />
        <Route path="newsPage/:id" element={<NewsPage />} />
        <Route path="order" element={<OrderFormCreate />} />
        <Route path="order/create/:tariffId" element={<OrderFormCreate />} />
        <Route path="allTariffs" element={<AllTariffs />} />
      </Route>
      <Route
        path="/5n"
        element={
          <Box
            sx={{
              width: '100%',
              height: '100vh',
            }}
          >
            <iframe
              src={config.lkLink}
              title="lk"
              style={{ width: '100%', height: '100%', border: 'none' }}
              // loading="lazy"
            ></iframe>
          </Box>
        }
      />
      <Route path="/profile" element={<ProfileLayout />}>
        <Route path="/profile/main" element={<Profile />} />
      </Route>
      <Route element={<ForUnloggedUsers />}>
        <Route path="login" element={<SignIn />} />
      </Route>
      <Route element={<RequireAuth requiredRoles={[Role.admin]} />}>
        <Route path="adminka" element={<AdminkaLayout />}>
          <Route path="/adminka/news" element={<AdminkaNews />} />
          <Route path="order/:id/" element={<Order />} />
          <Route path="order/edit/:orderId/" element={<OrderFormUpdate />} />
          <Route path="news/edit/:id" element={<NewsFormUpdate />} />
          <Route path="news/create/" element={<NewsFormCreate />} />
          <Route path="tariffs" element={<AdminkaWiredTariffs />} />
          <Route path="tariffs/wireLess" element={<AdminkaWireLessTariffs />} />
          <Route path="tariffs/archived" element={<AdminkaArchivedTariffs />} />
          <Route path="tariffs/create" element={<TariffFormCreate />} />
          <Route path="tariffs/edit/:id" element={<TariffFormEdit />} />
          <Route path="districts" element={<AdminkaDistricts />} />
          <Route path="district/create" element={<DistrictFormCreate />} />
          <Route path="district/edit/:id" element={<DistrictFormEdit />} />
          <Route path="orders" element={<AdminkaOrdersActive />} />
          <Route path="orders/archived" element={<AdminkaOrdersArchived />} />
          <Route path="orders/canceled" element={<AdminkaOrdersCanceled />} />
        </Route>
      </Route>
      <Route path="/forbidden" element={<Forbidden />} />
      <Route path="*" element={<E404 />} />
    </Routes>
  );
};

export default RouterView;
