import { fakeAxios as axios } from '../../utils/fakeAxios';
// import { baseAxios as axios } from '../axios/axios';
import { DistrictFormData } from '../../view/adminka/districts/districtForm/yupSchema';

export type IDistrcit = DistrictFormData & { id: string };
export type CreateDistrictDto = DistrictFormData;
export type UpdateDistrcitDto = Partial<CreateDistrictDto>;

const enpoint = 'district/';

const getDistricts = async () => {
  const districts = await axios.get<IDistrcit[]>(enpoint);
  return districts.data;
};

const getOneDistrict = async (id: string) => {
  const district = await axios.get<IDistrcit>(enpoint + id);
  if (district.data instanceof Array && district.data.length) {
    return district.data[0];
  }
  return district.data;
};

const createDistrict = async (createDitrictDto: CreateDistrictDto) => {
  const district = await axios.post<IDistrcit>(enpoint, {
    ...createDitrictDto,
  });
  return district.data;
};

const updateDistrict = async ({
  id,
  updateDistrictDto,
}: {
  id: string;
  updateDistrictDto: UpdateDistrcitDto;
}) => {
  const district = await axios.put<IDistrcit>(enpoint + id, {
    ...updateDistrictDto,
  });
  return district.data;
};

const deleteDistrict = async (id: string) => {
  const result = await axios.delete(enpoint + id);
  return result;
};

export { createDistrict, deleteDistrict, getDistricts, getOneDistrict, updateDistrict };
