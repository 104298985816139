import { Box, Button, Container, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';

import Navigation from '../navigation/Navigation';
import DaymohkLogo from '../../assets/images/logo.png';
import { tabletQuery } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { mainNavigationList } from '../constants';

import './descktop-menu.scss';

const DesktopMenu = () => {
  const navigate = useNavigate();
  const showTablet = useMediaQuery(tabletQuery);

  return (
    <nav className="main-navigation">
      <Container
        disableGutters
        sx={{
          paddingY: '10px',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              maxWidth: '65px',
              maxHeight: '65px',
              paddingY: '5px',
              cursor: 'pointer',
            }}
          >
            <img src={DaymohkLogo} alt="logo" onClick={() => navigate('/')} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Navigation listItems={mainNavigationList} />
            <Button
              variant="contained"
              className={!showTablet ? 'enable-tablet-btn' : 'enable-btn'}
            >
              <Link to="/order">Подключиться</Link>
            </Button>
          </Box>
        </Box>
      </Container>
    </nav>
  );
};

export default DesktopMenu;
