import { OrderFormData } from '../../view/orderPage/helpers/orderFormSchema';
import { fakeAxios as axios } from '../../utils/fakeAxios';
// import { baseAxios as axios } from '../axios/axios';

export type IOrder = Omit<
  OrderFormData,
  | 'aggreddToShareDate'
  | 'passportMainPagePhoto'
  | 'passportRegistrationPhoto'
  | 'birthday'
  | 'passportDate'
> & {
  id: string;
  passportMainPagePhoto: string;
  passportRegistrationPhoto: string;
  birthday: string;
  passportDate: string;
  status: string;
};
export type CreateOrderDto = Omit<IOrder, 'id' | 'status'>;
export type UpdateOrderDto = Partial<CreateOrderDto>;

const enpoint = 'order/';

export enum OrderStatusEnum {
  active = 'active',
  archived = 'archived',
  canceled = 'canceled',
}

const getAllOrders = async () => {
  const orders = await axios.get<IOrder[]>(enpoint);
  return orders.data;
};

const getAllActiveOrders = async () => {
  const orders = await axios.get<IOrder[]>(enpoint + OrderStatusEnum.active);
  return orders.data;
};

const getAllArchivedOrders = async () => {
  const orders = await axios.get<IOrder[]>(enpoint + OrderStatusEnum.archived);
  return orders.data;
};

const getAllCanseledOrders = async () => {
  const orders = await axios.get<IOrder[]>(enpoint + OrderStatusEnum.canceled);
  return orders.data;
};

const getOneOrder = async (id: string) => {
  const order = await axios.get<IOrder>(enpoint + id);
  return order.data;
};

const createOrder = async (createOrderDto: CreateOrderDto) => {
  const order = await axios.post<IOrder>(enpoint, {
    ...createOrderDto,
  });
  return order.data;
};

const updateOrder = async ({
  id,
  updateOrderDto,
}: {
  id: string;
  updateOrderDto: UpdateOrderDto;
}) => {
  const order = await axios.put<IOrder>(enpoint + id, {
    ...updateOrderDto,
  });
  return order.data;
};

const archiveOrder = async (id: string) => {
  const order = await axios.put<IOrder>(enpoint + id + '/archive');
  return order.data;
};

const canselOrder = async (id: string) => {
  const order = await axios.put<IOrder>(enpoint + id + '/cansel');
  return order.data;
};

const deleteOrder = async (id: string) => {
  const result = await axios.delete(enpoint + id);
  return result;
};

export {
  archiveOrder,
  canselOrder,
  createOrder,
  deleteOrder,
  getAllActiveOrders,
  getAllArchivedOrders,
  getAllCanseledOrders,
  getAllOrders,
  getOneOrder,
  updateOrder,
};
