import { Box, Typography } from '@mui/material';

import { UserSvg } from '../svgIcons';
import { ToLk } from '../Frame';

export const HeaderLinks = () => {
  return (
    <ToLk
      renderComp={(onClick) => (
        <Box onClick={onClick} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography component="p" variant="body1" sx={{ mr: '10px' }}>
            ЛИЧНЫЙ КАБИНЕТ
          </Typography>
          <UserSvg width="20px" height="20px" />
        </Box>
      )}
    />
  );
};
