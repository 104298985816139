import { Box, Container, Divider, Typography, useMediaQuery } from '@mui/material';

import withTheme from '../withTheme';
import theme from './footerTheme';
import DaymohkLogo from '../../assets/images/logo.png';

import './footer.scss';
import { ToLk } from '../Frame';

const Footer = () => {
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <footer className="footer">
      <Divider />
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingY: '10px',
        }}
      >
        <Box sx={{ maxWidth: '90px', maxHeight: '90px', mr: '1rem' }}>
          <img src={DaymohkLogo} alt="daymohkLogo" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: matches ? 'space-evenly' : 'center',
            alignItems: 'center',
            cursor: 'pointer',
            flexDirection: matches ? 'row' : 'column',
            flexGrow: 1,
            gap: '1rem',
          }}
        >
          <Typography component="p" variant="body1">
            Контакты
          </Typography>
          <Typography component="p" variant="body1">
            Тарифы
          </Typography>
          <ToLk
            renderComp={(onClick) => (
              <Typography onClick={onClick} component="p" variant="body1">
                Личный кабинет
              </Typography>
            )}
          />
          <Typography component="p" variant="body1">
            Способы оплаты
          </Typography>
        </Box>
      </Container>
      <Divider />
    </footer>
  );
};

export default withTheme({ theme, Component: Footer });
