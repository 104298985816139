import { fakeAxios as axios } from '../../utils/fakeAxios';
// import { baseAxios as axios } from '../axios/axios';
import { CreateNewsDto, UpdateNewsDto } from '../../view/adminka/news/main/contracts.news';
import { INews } from '../../storeSlices/newsSlice/contracts.news';

const enpoint = 'news/';

const getNews = async () => {
  const news = await axios.get<INews[]>(enpoint);
  return news.data;
};

const getOneNews = async (id: string) => {
  const news = await axios.get<INews>(enpoint + id);
  return news.data;
};

const createNews = async (createNewsDto: CreateNewsDto) => {
  const news = await axios.post<INews>(enpoint, {
    ...createNewsDto,
  });
  return news.data;
};

const updateNews = async (id: string, updateNews: UpdateNewsDto) => {
  const news = await axios.put<INews>(enpoint + id, {
    ...updateNews,
  });
  return news.data;
};

const deleteNews = async (id: string) => {
  const news = await axios.delete(enpoint + id);
  return news;
};

export { createNews, deleteNews, getNews, getOneNews, updateNews };
