import {
  CreateTariffDto,
  ITariff,
  UpdateTariffDto,
} from '../../storeSlices/tariffSlice/contracts.tariff';
import { AxiosInstance } from 'axios';
import { fakeAxios as axios } from '../../utils/fakeAxios';
// import { baseAxios as axios } from '../axios/axios';

const enpoint = 'tariff/';
const enpointArchived = 'tariff/archived';
const enpointWired = 'tariff/wired/active';
const enpointWireLess = 'tariff/wireLess/active';
const enpointWiredArcived = 'tariff/wired/active';
const enpointWireLessArchived = 'tariff/wireLess/active';

const getAllTariffsArchived = async () => {
  const tariffs = await axios.get<ITariff[]>(enpointArchived);
  return tariffs.data;
};

const getWiredTariffs = async () => {
  const tariffs = await axios.get<ITariff[]>(enpointWired);
  return tariffs.data;
};

const getWireLessTariffs = async () => {
  const tariffs = await axios.get<ITariff[]>(enpointWireLess);
  return tariffs.data;
};

const getWiredTariffsArchvied = async () => {
  const tariffs = await axios.get<ITariff[]>(enpointWiredArcived);
  return tariffs.data;
};

const getWireLessTariffsArchived = async () => {
  const tariffs = await axios.get<ITariff[]>(enpointWireLessArchived);
  return tariffs.data;
};
const getTariffs = async () => {
  const tariffs = await axios.get<ITariff[]>(enpoint);
  return tariffs.data;
};

const getOneTariff = async (id: string) => {
  const tariff = await axios.get<ITariff>(enpoint + id);
  return tariff.data;
};

const getOneWiredTariff = async (id: string) => {
  const tariff = await axios.get<ITariff>(enpointWired + id);
  return tariff.data;
};

const getOneWireLessTariff = async (id: string) => {
  const tariff = await axios.get<ITariff>(enpointWireLess + id);
  return tariff.data;
};

const createTariff = async (axios: AxiosInstance, createTariffDto: CreateTariffDto) => {
  const tariff = await axios.post<ITariff>(enpoint, createTariffDto);
  return tariff.data;
};

const updateTariff = async (id: string, updateTariffDto: UpdateTariffDto) => {
  const tariff = await axios.put<ITariff>(enpoint + id, updateTariffDto);
  return tariff.data;
};

const deleteTariff = async (id: string) => {
  const result = await axios.delete(enpoint + id);
  return result;
};

export {
  createTariff,
  deleteTariff,
  getAllTariffsArchived,
  getOneTariff,
  getOneWiredTariff,
  getOneWireLessTariff,
  getTariffs,
  getWiredTariffs,
  getWiredTariffsArchvied,
  getWireLessTariffs,
  getWireLessTariffsArchived,
  updateTariff,
};
