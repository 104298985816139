import { Box } from '@mui/material';
import { PhoneSvg, UserSvg } from '../svgIcons';
import { ToLk } from '../Frame';

const HamburgerNavIcons = () => {
  return (
    <Box className="hamburger-menu__icons-wrapper" sx={{ mr: '1rem', display: 'flex' }}>
      <PhoneSvg width="20px" height="20px" />
      <ToLk renderComp={(onClick) => <UserSvg onClick={onClick} width="25px" height="25px" />} />
    </Box>
  );
};

export default HamburgerNavIcons;
