import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getOneDistrict, IDistrcit } from '../../../api/district/districtApi';
import { IOrder } from '../../../api/order/orderApi';
import { useGetTariffQuery } from '../../../storeSlices/tariffSlice/query/tariffApiSlice';
import Spinner from '../../../components/spinner';
import OrderPresent from './OrderPresent';

type OrderProps = {
  order: IOrder;
};

const Order = ({ order }: OrderProps) => {
  const { data: tariff, isLoading: isTariffLoading } = useGetTariffQuery(order.tariffId);

  const getDistrict = useCallback(async () => {
    const district = await getOneDistrict(order.districtId);
    return district;
  }, [order]);

  const { isLoading: isDistrictLoading, data: district } = useQuery<IDistrcit | null>(
    'DISTRICT',
    getDistrict
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isTariffLoading || isDistrictLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isDistrictLoading, isTariffLoading]);

  return (
    <>
      {isLoading && <Spinner open={true} />}
      {tariff && district && <OrderPresent order={order} tariff={tariff} district={district} />}
    </>
  );
};

export default Order;
