type FakeAxiosResponse<T> = {
  data: T;
  status: number;
  statusText: string;
};

export const fakeAxios = {
  get: async <T>(url: string, config?: unknown): Promise<FakeAxiosResponse<T>> => {
    console.log(`GET request to: ${url}`, config);
    const mockedData: T = [] as unknown as T;
    return {
      data: mockedData,
      status: 200,
      statusText: 'OK',
    };
  },

  post: async <T>(url: string, data?: unknown, config?: unknown): Promise<FakeAxiosResponse<T>> => {
    console.log(`POST request to: ${url}`, { data, config });
    const mockedData: T = {} as T;
    return {
      data: mockedData,
      status: 201,
      statusText: 'Created',
    };
  },

  put: async <T>(url: string, data?: unknown, config?: unknown): Promise<FakeAxiosResponse<T>> => {
    console.log(`PUT request to: ${url}`, { data, config });
    const mockedData: T = {} as T;
    return {
      data: mockedData,
      status: 200,
      statusText: 'OK',
    };
  },

  delete: async <T>(url: string, config?: unknown): Promise<FakeAxiosResponse<T>> => {
    console.log(`DELETE request to: ${url}`, config);
    const mockedData: T = {} as T;
    return {
      data: mockedData,
      status: 200,
      statusText: 'OK',
    };
  },

  patch: async <T>(
    url: string,
    data?: unknown,
    config?: unknown
  ): Promise<FakeAxiosResponse<T>> => {
    console.log(`PATCH request to: ${url}`, { data, config });
    const mockedData: T = {} as T;
    return {
      data: mockedData,
      status: 200,
      statusText: 'OK',
    };
  },
};
